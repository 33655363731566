<template>
  <button @click="$emit('openMenu')">
    <div class="lines">
      <div class="line line-1"></div>
      <div class="line"></div>
      <div class="line"></div>
    </div>
  </button>
</template>

<script>
export default {
  name: "smBurgerMenu",
};
</script>

<style scoped lang="scss">
button {
  height: 46px;
  width: 46px;
  background-color: #606060;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  position: fixed;
  top: 25px;
  left: 25px;
  z-index: 3;
}
button:hover {
  background-color: #efe0de;
  transition: all 0.4s ease-in;
  height: 60px;
  width: 60px;
}
.line {
  height: 4px;
  margin: 3px 0px;
  width: 25px;
  background-color: #ffffff;
}
</style>
