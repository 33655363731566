<template>
  <div id="app">
    <sm-burger-menu @openMenu="toggleMenu"></sm-burger-menu>
    <sm-menu-open
      @closeMenuPlease="toggleMenu"
      v-show="menuOpened"
    ></sm-menu-open>
    <sm-hero-section></sm-hero-section>
    <sm-about-me></sm-about-me>
    <sm-work></sm-work>
    <sm-contact></sm-contact>
  </div>
</template>

<script>
import smHeroSection from "./components/smHeroSection.vue";
import smMenuOpen from "./components/smMenuOpen.vue";
import smBurgerMenu from "./components/smBurgerMenu.vue";
import smAboutMe from "./components/smAboutMe.vue";
import smWork from "./components/smWork.vue";
import smContact from "./components/smContact.vue";

export default {
  name: "App",
  components: {
    smHeroSection,
    smMenuOpen,
    smBurgerMenu,
    smAboutMe,
    smWork,
    smContact,
  },
  data: function () {
    return {
      menuOpened: false,
      projects: [],
    };
  },
  methods: {
    toggleMenu() {
      this.menuOpened = !this.menuOpened;
    },
  },
};
</script>

<style lang="scss">
$primary: #efe0de;
body {
  max-width: 100%;
  box-sizing: border-box;
}
h1,
h2,
h3 {
  font-weight: 800;
}
#app {
  font-family: "Playfair Display", serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #606060;
}
</style>
