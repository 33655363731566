<template>
  <div id="aboutme" class="container-fluid">
    <div class="row">
      <div class="col-md-6 d-none d-md-block col-img-soledad text-center">
        <div class="img-soledad-wrapper">
          <img
            class="img-soledad animate__animated animate__fadeInLeft"
            src="../assets/img/foto-soledad.png"
            alt="Foto Soledad"
          />
        </div>
        <div class="menu-about my-5">
          <ul>
            <li>
              <a
                class="text-dark about-link"
                @click.prevent="about = 1"
                href="#"
              >
                ME</a
              >
            </li>
            <li>
              <a
                class="text-dark about-link"
                @click.prevent="about = 2"
                href="#"
              >
                CHALLENGES
              </a>
            </li>
            <li>
              <a
                class="text-dark about-link"
                @click.prevent="about = 3"
                href="#"
              >
                SOLUTIONS
              </a>
            </li>
          </ul>
        </div>
        <div class="vertical-line-after-menu">
          <img src="../assets/img/vertical-line.png" />
        </div>
      </div>
      <div class="col-md-4 py-5 px-5">
        <img
          class="
            img-soledad-m img-fluid
            d-inline d-md-none
            animate__animated animate__fadeInUp
          "
          src="../assets/img/foto-soledad.png"
          alt="Foto Soledad"
        />
        <div
          class="
            about-me-circle
            mx-auto
            d-flex
            align-items-center
            justify-content-center
            my-5
          "
        >
          <h2>about me</h2>
        </div>
        <transition name="fade">
          <div v-if="about == 1" class="about-me-text">
            <p>Hello! I'm <span class="bold">Soledad Merchán.</span></p>
            <p>
              Interior designer with more than 20 years creating stylish and
              comfortable spaces.
            </p>
            <p class="bold">How do I do it?</p>
            <p>
              It's not magic, I assure you! It's a combination of knowledge,
              experience, taste and a good organization. If you have a residence
              or business and you want to improve its interior design to create
              a unique environment that you can identify with and that transmits
              your values...
            </p>
            <p class="bold">I can help you!</p>
          </div>
          <div v-else-if="about == 2" class="about-me-text">
            <p class="bold">INTERIOR DESIGN - HOME STAGING - REFORMS</p>
            <p>If you are currently in any of the following situations....</p>
            <ul class="about-me-list">
              <li>
                <i class="bi bi-lightbulb"></i> You are building your own
                property from scratch.
              </li>
              <li>
                <i class="bi bi-lightbulb"></i>
                You have set up a new business or you need to give your shop a
                new look.
              </li>
              <li>
                <i class="bi bi-lightbulb"></i>
                You have a flat that you want to rent, but it is not attractive.
              </li>
              <li>
                <i class="bi bi-lightbulb"></i>
                Your home has become boring and bland and you feel it no longer
                fits the way you are.
              </li>
            </ul>
            <p>
              SMILE, you've already taken the first step towards finding a new
              style you can identify with!
            </p>

            <p class="bold">I can help you!</p>
          </div>
          <div v-else-if="about == 3" class="about-me-text">
            <p class="bold">How can I help you?</p>

            <ul class="about-me-list">
              <li>
                <i class="bi bi-check"></i>By listening to your needs, interests
                and tastes.
              </li>
            </ul>
            <ul class="about-me-list">
              <li>
                <i class="bi bi-check"></i>Researching to get under your skin
                and getting to know your home or business in depth.
              </li>
            </ul>
            <ul class="about-me-list">
              <li>
                <i class="bi bi-check"></i>Defining your lifestyle... and the
                style of your next home or shop!
              </li>
            </ul>
            <ul class="about-me-list">
              <li>
                <i class="bi bi-check"></i>Creating a decoration and interior
                design project exclusively for you.
              </li>
            </ul>
            <p class="bold">
              Because... as you know: beauty is inside you, it's time to project
              it outside!
            </p>
          </div>
        </transition>
        <div class="arrows d-flex justify-content-around">
          <div class="arrow-left">
            <a v-show="about >= 2" @click="about--">
              <i class="bi bi-arrow-left"></i>
            </a>
          </div>
          <div class="arrow-right">
            <a v-show="about < 3" @click="about++">
              <i class="bi bi-arrow-right"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "smAboutMe",
  data: function () {
    return {
      about: 1,
    };
  },
  methods: {
    moreAboutMe() {
      this.aboutMe++;
    },
    lessAboutMe() {
      this.aboutMe--;
    },
  },
};
</script>

<style lang="scss">
.bold {
  font-weight: 800;
}
.col-img-soledad {
  position: relative;
}
.img-soledad-wrapper {
  position: relative;
}
.img-soledad {
  position: relative;
  margin-top: -350px;
  max-width: 500px;
}
.about-me-circle {
  background-color: #efe0de;
  height: 200px;
  width: 200px;
  border-radius: 50%;
  padding: 25px;
}
.about-me-text {
  min-height: 440px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
ul {
  padding: 0;
  li {
    list-style-type: none;
  }
}
.about-me-list {
  padding-left: 15px;
  li {
    padding-bottom: 5px;
  }
}
.arrows {
  font-size: 2em;
  a {
    cursor: pointer;
  }
}
.about-link {
  font-weight: 800;
  font-size: 1.3rem;
  text-decoration: none;
}
.about-link:hover {
  text-decoration: underline;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
