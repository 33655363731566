<template>
  <div class="hero__section">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-4 p-relative"></div>
        <div
          class="
            col-md-8
            right-side
            d-flex
            align-items-center
            justify-content-center
            flex-column
          "
        >
          <img
            class="sm-logo d-block animate__animated animate__fadeIn"
            src="../assets/img/logo-sm-grey.png"
            alt="Logo Soledad"
          />
          <div class="scroll-down d-block d-md-none text-center">
            <i class="bi bi-arrow-down-circle"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "smHeroSection",
};
</script>

<style lang="scss" scoped>
.right-side {
  background-color: #efe0de;
  min-height: 100vh;
  position: relative;
}
.sm-logo {
  width: 400px;
}
@media screen and (max-width: 767px) {
  .sm-logo {
    width: 250px;
  }
}
.scroll-down {
  font-size: 2rem;
  color: #ffffff;
  position: absolute;
  bottom: 50px;
  animation: updown 0.9s infinite ease-in;
}

@keyframes updown {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(10px);
  }
}
</style>
