<template>
  <li class="menu-item-li">
    <a @click="sendClose()" class="menu-item-link" :href="menudata.link">{{ menudata.title }}</a>
  </li>
</template>

<script>
export default {
  name: "smMenuItem",
  props: ["menudata"],
  methods: {
    sendClose(){
      this.$emit("sendCloseFromItem");
    },
  },
};
</script>

<style lang="scss">
li.menu-item-li {
  padding: 10px 20px;
  a.menu-item-link {
    font-weight: 800;
    text-decoration: none;
    font-size: 2rem;
  }
}
</style>
