<template>
  <div id="contact" class="contact-section">
    <div class="container mt-5">
      <div class="row">
        <div class="col-md-6"></div>
        <div class="col-md-4 text-center">
          <h2>
            <img
              class="d-none d-md-block work-lines"
              src="../assets/img/contact-lines.png"
            />
            CONTACT
          </h2>
        </div>
      </div>
      <div class="row py-5 align-items-center justify-content-center">
        <div class="col-md-6 left-contact text-left">
          <form class="mx-5">
            <div class="mb-3">
              <label for="nameField" class="form-label">Name</label>
              <input
                type="text"
                class="form-control form-field"
                id="nameField"
                placeholder="Your name"
                v-model="formValues.name"
                required
              />
            </div>
            <div class="mb-3">
              <label for="emailField" class="form-label">Email</label>
              <input
                type="email"
                class="form-control form-field"
                id="emailField"
                placeholder="name@email.com"
                v-model="formValues.email"
                required
              />
            </div>
            <div class="mb-3">
              <label for="messageField" class="form-label">Message</label>
              <textarea
                class="form-control form-field"
                id="messageField"
                rows="3"
                v-model="formValues.message"
              ></textarea>
            </div>
            <button
              @click.prevent="submitForm()"
              type="submit"
              class="btn btn-outline-dark mt-3"
            >
              Send Message
            </button>
          </form>
          <div v-show="responseMessage" class="alert alert-primary mt-3">
            {{ responseMessage }}
          </div>
        </div>
        <div
          class="col-md-6 right-contact mt-5 mt-md-0 text-center text-md-left"
        >
          <div class="contact-data mx-auto">
            <p><span style="font-weight: 800">Soledad Merchán</span></p>
            <p><i class="bi bi-envelope"></i> proyecto@soledadmerchan.com</p>
            <p><i class="bi bi-telephone"></i> +34 696 91 62 95</p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center text-center">
        <div class="col-xs-12 text-dark my-5 ig-logo">
          <a
            href="https://www.instagram.com/soledadmerchan_interiors/"
            target="_blank"
          >
            <i class="bi bi-instagram"></i>
          </a>
        </div>
        <div class="row justify-content-center text-center">
          <div class="col-xs-12 text-dark my-5 nubeseo">
            <a href="https://nubeseo.es/desarrollo-web/" target="_blank">
              Diseño web
            </a>
            y
            <a
              href="https://nubeseo.es/diseno-grafico/"
              target="_blank"
              rel="noopener noreferrer"
            >
              diseño de marca
            </a>
            por <a href="https://nubeseo.es/" target="_blank">NubeSeo</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "smContact",
  data: function () {
    return {
      formValues: {
        name: "",
        email: "",
        message: "",
      },
      responseMessage: "",
    };
  },
  methods: {
    submitForm() {
      let bodyFormData = new FormData();

      bodyFormData.append("your-name", this.formValues.name);
      bodyFormData.append("your-email", this.formValues.email);
      bodyFormData.append("your-message", this.formValues.message);

      this.$http({
        method: "post",
        url: "https://soledadmerchan.com/ddbb/wp-json/contact-form-7/v1/contact-forms/70/feedback",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          //handle success
          if (response.data.message) {
            this.responseMessage = response.data.message;
            setTimeout(() => {
              this.responseMessage = "";
            }, 3000);
            console.log(response);
          }
        })
        .catch((response) => {
          //handle error
          if (response.data.message) {
            this.responseMessage = response.data.message;
            setTimeout(() => {
              this.responseMessage = "";
            }, 3000);
            console.log(response);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
h2 {
  font-size: 7rem;
  position: relative;
}
.left-contact {
  border-right: solid 3px #efe0de;
}
.contact-data {
  p {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 767px) {
  h2 {
    font-size: 3.5rem;
  }
  .left-contact {
    border-right: none !important;
  }
  .contact-data {
    p {
      font-size: 1rem;
    }
  }
}
.work-lines {
  position: absolute;
  z-index: -20;
  top: 50%;
  left: -80%;
  width: 120%;
}
.left-contact {
  border-right: solid 3px #efe0de;
}
.ig-logo {
  font-size: 3rem;
}
.nubeseo {
  a {
    color: #d6bcb8;
    text-decoration: none;
  }
  a:hover {
    color: #606060;
  }
}
</style>
