<template>
  <transition
    enter-active-class="animate__animated animate__fadeInLeft"
    leave-active-class="animate__animated animate__fadeOutLeft"
  >
    <div class="menu-open">
      <div class="container-fluid">
        <div class="row">
          <div
            class="
              col-md-4
              menu-open-left
              d-flex
              align-items-center
              justify-content-center
            "
          >
            <ul>
              <sm-menu-item
                @sendCloseFromItem="sendCloseToParent"
                v-for="(item, index) in items"
                :menudata="item"
                :key="index"
              ></sm-menu-item>
            </ul>
          </div>
          <div class="col-md-8 menu-open-right d-none d-md-block"></div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import smMenuItem from "./smMenuItem.vue";

export default {
  name: "smMenuOpen",
  components: {
    smMenuItem,
  },
  data: function () {
    return {
      items: [
        {
          title: "ABOUT ME",
          link: "#aboutme",
        },
        {
          title: "WORK",
          link: "#work",
        },
        {
          title: "CONTACT",
          link: "#contact",
        },
      ],
    };
  },
  methods: {
    sendCloseToParent() {
      this.$emit("closeMenuPlease");
    },
  },
};
</script>

<style scoped lang="scss">
.menu-open {
  width: 100%;
  position: fixed;
  top: 0px;
  bottom: 0px;
  z-index: 2;
}
.menu-open-left {
  background-color: #606060;
  height: 100vh;
}
.menu-open-right {
  background-color: rgba($color: #606060, $alpha: 0.6);
}
.container-fluid {
  padding: 0;
}
</style>
