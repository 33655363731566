<template>
  <div class="work-expanded">
    <div class="container">
      <div class="row align-items-center justify-content-center mt-2 mt-md-5">
        <div class="col-md-8">
          <h1>
            {{ projectData.acf.project_title }}
            <img
              class="d-none d-md-block work-lines"
              src="../assets/img/work-lines.png"
            />
          </h1>
        </div>
        <div class="col-md-4">
          <a
            @click.prevent="sendClose()"
            class="back-to-link my-3 d-block"
            href="#"
          >
            <i class="bi bi-arrow-left"></i>
            BACK TO PROJECTS
          </a>
        </div>
      </div>
    </div>
    <CoolLightBox :items="items" :index="index" @close="index = null">
    </CoolLightBox>
    <div class="container">
      <div class="row mt-3 mt-md-5">
        <div
          class="col-md-6 col-lg-6 mb-4"
          v-for="(image, imageIndex) in items"
          :key="imageIndex"
          @click="index = imageIndex"
        >
          <img class="img-fluid gallery-item" :src="image" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CoolLightBox from "vue-cool-lightbox/src/components/CoolLightBox.vue";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

export default {
  name: "smWorkExpanded",
  components: {
    CoolLightBox,
  },
  props: ["projectData"],
  methods: {
    sendClose() {
      this.$emit("closeProject");
    },
    getGalleryImages() {
      let { project_gallery } = this.projectData.acf;
      project_gallery.forEach((image) => {
        this.items.push(image.url);
      });
    },
  },
  data: function () {
    return {
      items: [],
      index: null,
    };
  },
  created: function () {
    return this.getGalleryImages();
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 5rem;
  position: relative;
}
@media screen and (max-width: 992px) {
  h1 {
    font-size: 3rem;
  }
}
.work-expanded {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  z-index: 3;
  overflow: auto;
}
.back-to-link {
  color: #606060;
  text-decoration: none;
}
.back-to-link:hover {
  text-decoration: underline;
}
.gallery-item {
  cursor: pointer;
}
img {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
}
.work-lines {
  position: absolute;
  z-index: -20;
  top: 50%;
  left: 10%;
  width: 60%;
}
</style>
