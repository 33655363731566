<template>
  <div class="col-12 col-md-6 work-list-item mb-4">
    <a
      @click.prevent="showIndex(project.id)"
      class="work-list-item-link"
      href="#"
    >
      <div class="work-list-item-wrapper">
        <img
          class="img-fluid"
          :src="project.acf.project_cover_image.url"
          :alt="project.acf.project_title"
        />
        <span class="work-list-overlay"> {{ project.acf.project_title }} </span>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: "smWorkListItem",
  props: ["project", "indice"],
  methods: {
    showIndex(key) {
      console.log(key);
      this.$emit("selectedProject", key);
    },
  },
};
</script>

<style scoped lang="scss">
.work-list-item {
  position: relative;
  img {
    width: 100%;
    max-height: 350px;
    object-fit: cover;
  }
}
@media screen and (max-width: 992px) {
  .work-list-item {
    img {
      width: 100%;
      max-height: 280px;
    }
  }
}
.work-list-item-wrapper {
  position: relative;
}
.work-list-overlay {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0px;
  width: 0px;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 5px;
  color: #606060;
  font-weight: 800;
  font-size: 2rem;
  opacity: 0;
  background-color: rgba($color: #efe0de, $alpha: 0.5);
}
.work-list-item:hover .work-list-overlay {
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  width: 100%;
  opacity: 1;
}
</style>
