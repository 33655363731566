<template>
  <div class="container">
    <sm-loader v-show="loader"></sm-loader>
    <sm-work-expanded
      v-if="projectOpened"
      :projectData="selectedPj"
      @closeProject="closeProject()"
    ></sm-work-expanded>
    <div class="row mt-5">
      <sm-work-list-item
        v-for="(project, index) in projects"
        :project="project"
        :key="index"
        :indice="index"
        @selectedProject="selectProject"
      ></sm-work-list-item>
    </div>
  </div>
</template>

<script>
import smWorkListItem from "./smWorkListItem.vue";
import smWorkExpanded from "./smWorkExpanded.vue";
import smLoader from "./smLoader.vue";

export default {
  name: "smWorks",
  components: {
    smWorkListItem,
    smWorkExpanded,
    smLoader,
  },
  created: function () {
    this.$http
      .get("https://soledadmerchan.com/ddbb/wp-json/wp/v2/project")
      .then((response) => {
        console.log(response.data);
        this.projects = response.data;
      });
  },
  data: function () {
    return {
      projects: [],
      loader: false,
      projectOpened: false,
      selectedPj: {},
    };
  },
  methods: {
    loading() {
      return (this.loader = true);
    },
    stopLoading() {
      setTimeout(() => {
        this.loader = false;
      }, 1500);
    },
    closeProject() {
      this.projectOpened = !this.projectOpened;
      document.body.style.overflow = "auto";
    },
    openProject() {
      this.projectOpened = true;
      document.body.style.overflow = "hidden";
    },
    selectProject(key) {
      this.loading();
      this.$http
        .get(`https://soledadmerchan.com/ddbb/wp-json/wp/v2/project/${key}`)
        .then((response) => {
          this.selectedPj = response.data;
          this.stopLoading();
          this.openProject();
        });
    },
  },
};
</script>
