<template>
  <div id="work" class="work-section">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-md-4 text-center">
          <h2 class="animate__animated animate__fadeInRight">
            <img
              class="d-none d-md-block work-lines"
              src="../assets/img/work-lines.png"
            />
            <span class="title">WORK</span>
          </h2>
        </div>
        <div class="col-md-6"></div>
      </div>
      <div class="row">
        <sm-works></sm-works>
      </div>
    </div>
  </div>
</template>

<script>
import smWorks from "./smWorks.vue";

export default {
  name: "smWork",
  components: {
    smWorks,
  },
};
</script>

<style scoped lang="scss">
h2 {
  font-size: 7rem;
  position: relative;
}
@media screen and (max-width: 767px) {
  h2 {
    font-size: 4rem;
  }
}
.work-lines {
  position: absolute;
  z-index: -20;
  top: 50%;
  left: 50%;
  width: 120%;
}
.title {
  z-index: 1;
}
</style>
