<template>
  <transition leave-active-class="animate__animated animate__fadeOutUpBig">
    <div class="loader-wrapper">
      <img
        class="d-block loader"
        src="@/assets/img/logo-sm-grey.png"
        height="60px"
        alt="loader"
      />
    </div>
  </transition>
</template>

<script>
export default {
  name: "smLoader",
};
</script>

<style lang="scss">
.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #efe0de;
  overflow: auto;
  z-index: 4;
}
.loader {
  animation: latir 0.9s infinite ease-in;
}
@keyframes latir {
  from {
    height: 60px;
  }
  to {
    height: 70px;
  }
}
</style>
