import "@popperjs/core";
import "bootstrap";
import "animate.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./assets/scss/styles.scss";
import axios from "axios";
import Vue from "vue";
import App from "./App.vue";

(function (d) {
  var w = d.documentElement.offsetWidth,
    t = d.createTreeWalker(d.body, NodeFilter.SHOW_ELEMENT),
    b;
  while (t.nextNode()) {
    b = t.currentNode.getBoundingClientRect();
    if (b.right > w || b.left < 0) {
      t.currentNode.style.setProperty("outline", "1px dotted red", "important");
      console.log(t.currentNode);
    }
  }
})(document);

Vue.prototype.$http = axios;

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
}).$mount("#app");
